export const NRA = 66
const stateBenefit = 1063.17
const survivorsPensionChildAnnualAmount = 1878.48
const survivorsPensionAnnualAmount = 10879.53

export const deathMaxAgeEntry = 75
export const deathMaxAgePlusTerm = 79
export const sicMaxAgeEntry = 60
export const sicMaxAgePlusTerm = 74
export const utilities = 400

export const getStatePension = (numChildren: number) => {
    const childBenefitAmountAnnual =
        survivorsPensionChildAnnualAmount * numChildren
    if (numChildren > 0) {
        return Number(
            (
                (childBenefitAmountAnnual + survivorsPensionAnnualAmount) /
                12
            ).toFixed(2)
        )
    } else {
        return Number((survivorsPensionAnnualAmount / 12).toFixed(2))
    }
}

// get the term to pass to the API
export const calcTerm: any = (
    personAge: number,
    benefitType: string,
    maxAge: number,
    maxAgePlusTerm: number,
    children: number,
    childAge: number,
    mortgage?: string
) => {
    let term
    if (benefitType === 'Life' || benefitType === 'Sic') {
        if (children) {
            //if youngest >= 18 then term = 68-your age
            if (childAge >= 18) {
                term = 68 - (Number(personAge) + 1)
            } else {
                term = 25 - childAge
            }
        } else {
            term = Math.min(
                (benefitType === 'Sic' ? maxAge : NRA) -
                    (Number(personAge) + 1),
                20
            )
        }
    } else if (benefitType === 'BillPay') {
        if (children && childAge < 18 && mortgage) {
            //children
            term = Math.min(
                Math.max(personAge, 25 - childAge),
                NRA - (Number(personAge) + 1)
            )
        } else if (children && childAge < 18 && !mortgage) {
            term = Math.min(NRA - (Number(personAge) + 1), 25 - childAge)
        } else if (!children && mortgage) {
            term = Math.min(personAge, NRA - (Number(personAge) + 1))
            return Math.min(Number(mortgage), term)
        } else {
            term = Math.min(NRA - (Number(personAge) + 1), 20)
        }
    }

    var agePlusTerm = Number(personAge) + Number(term)
    if (personAge > maxAge) {
        term = 0
    } else if (agePlusTerm > maxAgePlusTerm) {
        term = maxAgePlusTerm - (Number(personAge) + 1)
    }

    return term
}

const checkForNegativeValue = (val: number) => {
    return val < 0 ? 0 : val
}

/**
 * @param {number} income 
 * @param {boolean} existingSICCover
 * @param {number} existingSICCoverAmount 
 * @param {boolean} IneligibleForBillCover
 * return an object with standard, essential and comprehensive plans for specified illness cover based on a users income
 ************************************************************************************
 * Cover Amount Calculation
    - Standard: 6 months income
    - Essential: 12 months income
    - Comprehensive: 24 months income
 ************************************************************************************
 * Cover Amount Calculation (Occupation Eligible for Bill Cover):
    - Comprehensive = (Net Monthly Income x 24) – current plans – existing assets
    - Essential = (Net Monthly Income x 12) – current plans – existing assets
    - Standard = (Net Monthly Income x 6) – current plans – existing assets
**************************************************************************************
 * Cover Amount Calculation (Occupation Ineligible for Bill Cover):
    - Comprehensive = (Net Monthly Income x 48) – current plans – existing assets
    - Essential = (Net Monthly Income x 24) – current plans – existing assets
    - Standard = (Net Monthly Income x 12) – current plans – existing assets
 */
export const getSpecifiedIllnessCover = (
    income: number,
    existingSICCoverAmount?: number,
    IneligibleForBillCover?: string
) => {
    if (existingSICCoverAmount === undefined) {
        existingSICCoverAmount = 0
    }
    return {
        StandardPlan: checkForNegativeValue(
            income * (IneligibleForBillCover === 'Y' ? 12 : 6) -
                existingSICCoverAmount
        ),
        EssentialPlan: checkForNegativeValue(
            income * (IneligibleForBillCover === 'Y' ? 24 : 12) -
                existingSICCoverAmount
        ),
        ComprehensivePlan: checkForNegativeValue(
            income * (IneligibleForBillCover === 'Y' ? 48 : 24) -
                existingSICCoverAmount
        ),
    }
}

export const getBillPayOccupationClassCd = (
    occupationSelect: string,
    occupations: any[]
) => {
    const filteredOccupation = occupations.filter(
        (item) => item['value'] === occupationSelect
    )
    if (filteredOccupation.length > 0) {
        return +filteredOccupation[0].BillPayOccupationClass
    }
    return null
}

/**
 * @param {string} married
 * @param {number} income1
 * @param {number} children
 * @param {number} youngetChildAge
 * @param {number} existingCover
 * @param {number} existingCoverAmt
 */
export const getDecreasingLifeCover = (
    married: string,
    income1: number,
    children: number,
    youngetChildAge: number,
    existingCover: string,
    mortgagePayment: number,
    existingCoverAmt?: number,
    age?: number,
    mortgageTerm?: number
) => {
    const termNoChild = calcTerm(
        age,
        'Life',
        deathMaxAgeEntry,
        sicMaxAgePlusTerm,
        0,
        0,
        mortgageTerm
    )

    let StandardPlan = 0,
        EssentialPlan = 0,
        ComprehensivePlan = 0

    if (children !== 0 && married === 'Y' && existingCoverAmt) {
        // have children, married and have existing cover
        // get the term
        const statePension = getStatePension(children)
        const term = 25 - youngetChildAge
        const existingCover = existingCoverAmt / term / 12
        const gap =
            (income1 - Number(statePension) - existingCover - mortgagePayment) *
            12 *
            term
        StandardPlan = checkForNegativeValue(Math.round(gap * 0.5))
        EssentialPlan = checkForNegativeValue(Math.round(gap * 0.75))
        ComprehensivePlan = checkForNegativeValue(Math.round(gap))
    } else if (married === 'Y' && existingCoverAmt) {
        // no children, married and have existing cover
        const statePension = getStatePension(children)
        const existingCover = existingCoverAmt / termNoChild / 12
        const gap =
            (income1 - statePension - existingCover - mortgagePayment) *
            12 *
            termNoChild
        StandardPlan = checkForNegativeValue(Math.round(gap * 0.5))
        EssentialPlan = checkForNegativeValue(Math.round(gap * 0.75))
        ComprehensivePlan = checkForNegativeValue(Math.round(gap))
    } else if (married === 'N' && existingCoverAmt) {
        // no children, not married and have existing cover
        const existingCover = existingCoverAmt / termNoChild / 12
        const gap =
            (income1 - stateBenefit - existingCover - mortgagePayment) *
            12 *
            termNoChild
        StandardPlan = checkForNegativeValue(Math.round(gap * 0.5))
        EssentialPlan = checkForNegativeValue(Math.round(gap * 0.75))
        ComprehensivePlan = checkForNegativeValue(Math.round(gap))
    } else if (married === 'Y') {
        // no children, married and no existing cover
        const statePension = getStatePension(children)
        const gap =
            (income1 - statePension - mortgagePayment) * 12 * termNoChild
        StandardPlan = checkForNegativeValue(Math.round(gap * 0.5))
        EssentialPlan = checkForNegativeValue(Math.round(gap * 0.75))
        ComprehensivePlan = checkForNegativeValue(Math.round(gap))
    }
    return { StandardPlan, EssentialPlan, ComprehensivePlan }
}

/**
 * @param {number} mortgageRepayments
 * @param {number} income1
 * @param {boolean} married
 * @param {boolean} IneligibleForBillCover
 * return an object with a value for the bill cover
 */
export const getBillCover = (
    mortgageRepayments: number,
    married: string,
    income1: number,
    income2?: number,
    IneligibleForBillCover?: string
) => {
    if (IneligibleForBillCover === 'Y') return 0
    if (married === 'Y' && income2) {
        const combinedIncome = income1 + income2
        return {
            Life1:
                Math.round(mortgageRepayments * (income1 / combinedIncome)) +
                utilities,
            Life2:
                Math.round(mortgageRepayments * (income2 / combinedIncome)) +
                utilities,
        }
    } else {
        const cover = Math.max(Number(mortgageRepayments), income1 * 0.4)
        // if 40% of the income is greater than or equals 2000 return the mortgage repayments + utilities
        return cover <= 2000
            ? Number(mortgageRepayments) + utilities
            : cover + utilities
    }
}

/**
 * @param {number} mortgageTerm
 * @param {string} youngestChild1
 * return an object with a value for the bill term
 */
export const getBillCoverExpiryTerm = (mortgageTerm: number, age: number) => {
    return {
        StandardPlan: 2,
        EssentialPlan: 5,
        ComprehensivePlan:
            mortgageTerm > 0 ? mortgageTerm : NRA - age < 20 ? NRA - age : 20,
    }
}

export const getPlans = (
    joint: string,
    age1: number,
    mortgageTerm1: number,
    married1: string,
    income1: number,
    numChildren1: number,
    youngestChild1: number,
    mortgagePayment1: number,
    existingSIC1: number,
    existingLife1: string,
    existingLifeAmt1?: number,
    age2?: number,
    income2?: number,
    existingSIC2?: number,
    existingLife2?: string,
    existingLifeAmt2?: number,
    IneligibleForBillCover1?: string,
    IneligibleForBillCover2?: string
) => {
    const specifiedIllness1 = getSpecifiedIllnessCover(
        income1,
        existingSIC1,
        IneligibleForBillCover1
    )
    const billCover1 = getBillCoverExpiryTerm(mortgageTerm1, age1)
    const decreasingLifeCover1 = getDecreasingLifeCover(
        married1,
        income1,
        numChildren1,
        youngestChild1,
        existingLife1,
        mortgagePayment1,
        existingLifeAmt1,
        age1,
        mortgageTerm1
    )
    if (joint === 'Y') {
        const specifiedIllness2 = getSpecifiedIllnessCover(
            income2!,
            existingSIC2,
            IneligibleForBillCover2
        )
        const billCover2 = getBillCoverExpiryTerm(mortgageTerm1, age2!)
        const decreasingLifeCover2 = getDecreasingLifeCover(
            married1,
            income2!,
            numChildren1,
            youngestChild1,
            existingLife2!,
            mortgagePayment1,
            existingLifeAmt2,
            age2,
            mortgageTerm1
        )
        const values = {
            specifiedIllness1,
            decreasingLifeCover1,
            billCover1,
            specifiedIllness2,
            billCover2,
            decreasingLifeCover2,
        }
        return values
    } else {
        const specifiedIllness2 = null,
            billCover2 = null,
            decreasingLifeCover2 = null
        const values = {
            specifiedIllness1,
            decreasingLifeCover1,
            billCover1,
            specifiedIllness2,
            billCover2,
            decreasingLifeCover2,
        }
        return values
    }
}

export const getOnePlanParams: any = (
    quoteData: any,
    type: string,
    occupations: any
) => {
    const {
        smokerCd1,
        smokerCd2,
        age1,
        age2,
        mortgageTerm,
        mortgagePayment,
        maritalStatus1,
        childNum1,
        youngestChild1,
        income1,
        income2,
        lifeCoverAmt1,
        lifeCoverConversion1,
        specifiedIllnessAmt1,
        specifiedIllnessConversion1,
        jointLife,
        occupationSelect1,
        occupationSelect2,
        billPayDeferredWeeks1,
        lifeCoverAmt2,
        lifeCoverConversion2,
        specifiedIllnessAmt2,
        specifiedIllnessConversion2,
        incomeProtection,
        incomeProtection2,
    } = quoteData

    // return 0 if user has no children
    const numChildren = childNum1 ? Number(childNum1) : 0
    const youngestChildOne = quoteData.youngestChild1
        ? Number(youngestChild1)
        : 0

    // get life cover data
    const lifeTerm = calcTerm(
        age1,
        'Life',
        deathMaxAgeEntry,
        deathMaxAgePlusTerm,
        numChildren,
        youngestChildOne
    )
    const lifeCoverAmounts = getDecreasingLifeCover(
        maritalStatus1,
        income1,
        numChildren,
        youngestChildOne,
        lifeCoverConversion1,
        mortgagePayment,
        lifeCoverAmt1,
        age1,
        mortgageTerm
    )

    // get sic cover data
    const sicTerm = calcTerm(
        age1,
        'Sic',
        deathMaxAgeEntry,
        sicMaxAgePlusTerm,
        numChildren,
        youngestChildOne
    )

    const sicCoverAmounts = getSpecifiedIllnessCover(
        income1,
        specifiedIllnessAmt1,
        specifiedIllnessConversion1
    )

    const BillPayOccupationClassCd1 = getBillPayOccupationClassCd(
        occupationSelect1,
        occupations
    )

    const BillPayOccupationClassCd2 = getBillPayOccupationClassCd(
        occupationSelect2,
        occupations
    )

    // get bill cover data
    const billTerm = calcTerm(
        age1,
        'BillPay',
        deathMaxAgeEntry,
        deathMaxAgePlusTerm,
        numChildren,
        youngestChild1,
        mortgageTerm
    )

    const billCoverAmount = getBillCover(
        mortgagePayment,
        maritalStatus1,
        income1
    )

    const defaultParams = {
        age1,
        income1,
        smokerCd1,
        maritalStatus1,
        occupationSelect1,
        childNum1,
        mortgagePayment,
        billPayOccupationClassCd1: BillPayOccupationClassCd1,
    }

    // jointLife
    const jointParams = {
        age2,
        jointLife: true,
        income2,
        smokerCd2,
        occupationSelect2,
        billPayOccupationClassCd2: BillPayOccupationClassCd2,
    }

    let user2Standard = null,
        user2Essential = null,
        user2Comprehensive = null

    if (jointLife === 'Y') {
        const lifeTerm2 = calcTerm(
            age2,
            'Life',
            deathMaxAgeEntry,
            deathMaxAgePlusTerm,
            numChildren,
            youngestChildOne
        )
        const lifeCoverAmounts2 = getDecreasingLifeCover(
            maritalStatus1,
            income2,
            numChildren,
            youngestChildOne,
            lifeCoverConversion2,
            mortgagePayment,
            lifeCoverAmt2,
            age2,
            mortgageTerm
        )

        // get sic cover data
        const sicTerm2 = calcTerm(
            age2,
            'Sic',
            deathMaxAgeEntry,
            sicMaxAgePlusTerm,
            numChildren,
            youngestChildOne
        )

        const sicCoverAmounts2 = getSpecifiedIllnessCover(
            income2,
            specifiedIllnessAmt2,
            specifiedIllnessConversion2
        )

        // get bill cover data
        const billTerm2 = calcTerm(
            age1,
            'BillPay',
            deathMaxAgeEntry,
            deathMaxAgePlusTerm,
            numChildren,
            youngestChild1,
            mortgageTerm
        )

        const billCoverAmount2 = getBillCover(
            mortgagePayment,
            maritalStatus1,
            income2
        )

        user2Standard = {
            lifeCoverTerm2: lifeTerm2,
            lifeCoverAmt2: lifeCoverAmounts2.StandardPlan,
            specifiedIllnessTerm2: sicTerm2,
            specifiedIllnessAmt2: sicCoverAmounts2.StandardPlan,
            ...(incomeProtection2 === 'Y'
                ? null
                : {
                      billPayTerm2: billTerm2,
                      billPayAmt2: billCoverAmount2,
                      billPayDeferredWeeks2: 13,
                      billPayConversion2: 'Y',
                      billPayExpiration2: 2,
                  }),
        }

        user2Essential = {
            lifeCoverTerm2: lifeTerm2,
            lifeCoverAmt2: lifeCoverAmounts2.EssentialPlan,
            specifiedIllnessTerm2: sicTerm2,
            specifiedIllnessAmt2: sicCoverAmounts2?.EssentialPlan,
            ...(incomeProtection2 === 'Y'
                ? null
                : {
                      billPayTerm2: billTerm2,
                      billPayAmt2: billCoverAmount2,
                      billPayDeferredWeeks2: 13,
                      billPayConversion2: 'Y',
                      billPayExpiration2: 5,
                  }),
        }

        user2Comprehensive = {
            lifeCoverTerm2: lifeTerm2,
            lifeCoverAmt2: lifeCoverAmounts2.ComprehensivePlan,
            specifiedIllnessTerm2: sicTerm2,
            specifiedIllnessAmt2: sicCoverAmounts2?.ComprehensivePlan,
            ...(incomeProtection2 === 'Y'
                ? null
                : {
                      billPayTerm2: billTerm2,
                      billPayAmt2: billCoverAmount2,
                      billPayDeferredWeeks2: 13,
                      billPayConversion2: 'Y',
                      billPayExpiration2: 99,
                  }),
        }
    }

    // return an object to pass as params to the API
    switch (type) {
        case 'standard':
            return {
                ...(jointLife === 'Y'
                    ? { ...jointParams, ...user2Standard }
                    : null),
                ...defaultParams,
                lifeCoverTerm1: lifeTerm,
                lifeCoverAmt1: lifeCoverAmounts.StandardPlan,
                specifiedIllnessTerm1: sicTerm,
                specifiedIllnessAmt1: sicCoverAmounts.StandardPlan,
                ...(incomeProtection === 'Y'
                    ? null
                    : {
                          billPayTerm1: billTerm,
                          billPayAmt1: billCoverAmount,
                          billPayDeferredWeeks1: 13,
                          billPayConversion1: 'Y',
                          billPayExpiration1: 2,
                      }),
            }
        case 'essential':
            return {
                ...(jointLife === 'Y'
                    ? { ...jointParams, ...user2Essential }
                    : null),
                ...defaultParams,
                lifeCoverTerm1: lifeTerm,
                lifeCoverAmt1: lifeCoverAmounts.EssentialPlan,
                specifiedIllnessTerm1: sicTerm,
                specifiedIllnessAmt1: sicCoverAmounts?.EssentialPlan,
                ...(incomeProtection === 'Y'
                    ? null
                    : {
                          billPayTerm1: billTerm,
                          billPayAmt1: billCoverAmount,
                          billPayDeferredWeeks1: 13,
                          billPayConversion1: 'Y',
                          billPayExpiration1: 5,
                      }),
            }
        case 'comprehensive':
            return {
                ...(jointLife === 'Y'
                    ? { ...jointParams, ...user2Comprehensive }
                    : null),
                ...defaultParams,
                lifeCoverTerm1: lifeTerm,
                lifeCoverAmt1: lifeCoverAmounts.ComprehensivePlan,
                specifiedIllnessTerm1: sicTerm,
                specifiedIllnessAmt1: sicCoverAmounts?.ComprehensivePlan,
                billPayTerm1: billTerm,
                ...(incomeProtection === 'Y'
                    ? null
                    : {
                          billPayAmt1: billCoverAmount,
                          billPayDeferredWeeks1: billPayDeferredWeeks1
                              ? billPayDeferredWeeks1
                              : 13,
                          billPayConversion1: 'Y',
                          billPayExpiration1: 99,
                      }),
            }
        default:
            break
    }
}
